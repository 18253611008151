import React, { useState, useEffect, useCallback } from "react"

interface Item {
  id: string
  count: number
  type: 'product' | 'merch'
}

export interface State {
  open: boolean
  cart: Item[]
  toggleCart: () => void
  addToCart: (itemId: string, type: 'product' | 'merch') => void
  updateQty: (itemId: string, count: number, type: 'product' | 'merch') => void
  removeFromCart: (itemId: string, type: 'product' | 'merch') => void
  clearCart: () => void
}

export const Context: React.Context<State> = React.createContext({
  cart: []
} as unknown as State)

function useCart() {
  const [cart, setCart] = useState<State["cart"]>([])

  useEffect(() => {
    if (typeof window !== "undefined") {
      const savedCart = localStorage.getItem('cart')
      if (savedCart) {
        setCart(JSON.parse(savedCart))
      }
    }
  }, [])

  const updateCart = useCallback((newCart: State["cart"]) => {
    setCart(newCart)
    if (typeof window !== "undefined") {
      localStorage.setItem('cart', JSON.stringify(newCart))
    }
  }, [])

  return [cart, updateCart] as const
}

const Provider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [open, setOpen] = useState(false)
  const [cart, updateCart] = useCart()

  function toggleCart() {
    setOpen(!open)
  }

  function addToCart(itemId: string, type: 'product' | 'merch') {
    const newCart = [...cart]
    const itemIndex = newCart.findIndex(item => item.id === itemId && item.type === type)
    if (itemIndex !== -1) {
      newCart[itemIndex].count += 1
    } else {
      newCart.push({ id: itemId, count: 1, type })
    }
    updateCart(newCart)
    setOpen(true)
  }

  function updateQty(itemId: string, count: number, type: 'product' | 'merch') {
    const newCart = cart.map(item => 
      item.id === itemId && item.type === type ? { ...item, count } : item
    ).filter(item => item.count > 0)
    updateCart(newCart)
  }

  function removeFromCart(itemId: string, type: 'product' | 'merch') {
    const newCart = cart.filter(item => !(item.id === itemId && item.type === type))
    updateCart(newCart)
  }

  function clearCart() {
    updateCart([])
  }

  const contextValue: State = {
    open,
    cart,
    toggleCart,
    addToCart,
    updateQty,
    removeFromCart,
    clearCart,
  }

  return (
    <Context.Provider value={contextValue}>
      {children}
    </Context.Provider>
  )
}

export default ({ element }: { element: React.ReactNode }) => (
  <Provider>{element}</Provider>
)
