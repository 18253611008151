exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blogs-tsx": () => import("./../../../src/pages/blogs.tsx" /* webpackChunkName: "component---src-pages-blogs-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-merch-tsx": () => import("./../../../src/pages/merch.tsx" /* webpackChunkName: "component---src-pages-merch-tsx" */),
  "component---src-pages-offer-tsx": () => import("./../../../src/pages/offer.tsx" /* webpackChunkName: "component---src-pages-offer-tsx" */),
  "component---src-pages-our-story-tsx": () => import("./../../../src/pages/our-story.tsx" /* webpackChunkName: "component---src-pages-our-story-tsx" */),
  "component---src-pages-recipes-tsx": () => import("./../../../src/pages/recipes.tsx" /* webpackChunkName: "component---src-pages-recipes-tsx" */),
  "component---src-pages-reviews-tsx": () => import("./../../../src/pages/reviews.tsx" /* webpackChunkName: "component---src-pages-reviews-tsx" */),
  "component---src-pages-sample-tsx": () => import("./../../../src/pages/sample.tsx" /* webpackChunkName: "component---src-pages-sample-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */),
  "component---src-pages-store-locator-tsx": () => import("./../../../src/pages/store-locator.tsx" /* webpackChunkName: "component---src-pages-store-locator-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-generic-tsx": () => import("./../../../src/templates/generic.tsx" /* webpackChunkName: "component---src-templates-generic-tsx" */),
  "component---src-templates-merch-tsx": () => import("./../../../src/templates/merch.tsx" /* webpackChunkName: "component---src-templates-merch-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-recipe-tsx": () => import("./../../../src/templates/recipe.tsx" /* webpackChunkName: "component---src-templates-recipe-tsx" */)
}

